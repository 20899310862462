<template>
  <div>
    {{ $t("components.stylesComponent.styles") }}
    <b-list-group>
      <b-list-group-item v-for="(item, index) in content" :key="index">
        <b-row>
          <b-col sm="auto">
            <b-form-checkbox
              v-model="item.certainty"
              :name="'form-element-style-certainty-' + index"
              switch
              @change="changed"
            >
              {{
                item.certainty
                  ? $t("components.stylesComponent.certain")
                  : $t("components.stylesComponent.uncertain")
              }}
            </b-form-checkbox>
          </b-col>
          <b-col>
            <multiselect
              v-if="styles.length > 0"
              v-model="item.style.id"
              :options="styles.map(i => i.id)"
              :custom-label="styleLabel"
              :show-labels="false"
              @select="changed"
            />
          </b-col>
          <b-col sm="auto">
            <b-button
              v-t="'components.stylesComponent.delete'"
              @click="deleteStyle(index)"
            />
          </b-col>
        </b-row>
        <sources-selector
          v-if="item['sources']"
          :id="'style-' + index + '-sources'"
          v-model="item['sources']"
          @change="changed"
        />
      </b-list-group-item>
    </b-list-group>
    <b-button v-t="'components.stylesComponent.new'" @click="addStyle" />
  </div>
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "StylesComponent",
  components: {
    SourcesSelector: () => import("@/components/SourcesSelector")
  },
  props: {
    value: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      content: this.value,
      styles: []
    };
  },
  watch: {
    value() {
      this.content = this.value;
    }
  },
  methods: {
    handleInput(e) {
      this.$emit("input", this.content);
    },
    changed() {
      this.$emit("change");
    },
    addStyle() {
      this.content.push({ style: { id: null }, certainty: true, sources: [] });
      this.$emit("change");
    },
    deleteStyle(index) {
      this.content.splice(index, 1);
      this.$emit("change");
    },
    styleLabel(style) {
      return (
        this.styles.find(x => x.id == style).style +
        " (" +
        (this.styles.find(x => x.id == style).sv + ")")
      );
    }
  },
  apollo: {
    styles() {
      return {
        query: gql`
          query styles {
            styles {
              id
              style
              sv
            }
          }
        `,
        result(result) {
          if (result.stale) {
            // result is called again with stale = true
            return;
          }

          this.styles = result.data.styles.sort((a, b) =>
            a.style.localeCompare(b.style, "sv")
          );
        }
      };
    }
  }
};
</script>
